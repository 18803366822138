import accounting from './accounting/index.js'
import clients from './clients/index.js'
import documents from './documents/index.js'
import reminders from './reminders/index.js'
import risk from './risk/index.js'
import shareholders from './shareholders/index.js'
import accountingData from './AccountingData/index'

export default {
    ...accounting,
    ...clients,
    ...documents,
    ...reminders,
    ...risk,
    ...shareholders,
    ...accountingData
}
