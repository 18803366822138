import {
  httpClient,
  authorizationHeader,
  handleErrors,
} from '@src/utils/httpClient'
import firebase from '@src/firebase/app'

export const deleteClient = (clientId) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      await httpClient.delete('/client', {
        body: {
          clientId,
        },
        ...authorizationHeader(idToken),
      })
      resolve()
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })
