import {
  authorizationHeader,
  httpClient,
  handleErrors,
} from '@utils/httpClient'

import firebase from '@src/firebase/app'
export const getDocumentsByClientId = (clientId) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data: { documents },
      } = await httpClient.get('/documents', {
        params: {
          clientId,
        },
        ...authorizationHeader(idToken),
      })
      resolve(documents)
    } catch (err) {
      const error = handleErrors(err)
      reject(error)
    }
  })

export const getAllDocuments = () =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data: { documents },
      } = await httpClient.get('/document/all', {
        ...authorizationHeader(idToken),
      })

      resolve(documents)
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })

export const getFileDownload = (documentId) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const { data, headers } = await httpClient.get('/document', {
        params: { documentId },
        ...authorizationHeader(idToken),
        responseType: 'blob',
      })
      const fileName = headers.filename
      resolve({ data, fileName })
    } catch (err) {
      reject(err)
    }
  })

export const getDocumentById = (documentId) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const { data } = await httpClient.get('/getDocumentById', {
        params: { documentId },
        ...authorizationHeader(idToken)
      })
      resolve(data)
    } catch (err) {
      reject(err)
    }
  })