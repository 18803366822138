import {
  authorizationHeader,
  httpClient,
  handleErrors,
} from '@utils/httpClient'

import firebase from '@src/firebase/app'

export const getAccountingData = (clientId) => new Promise(async (resolve, reject) => {
  try {
    const idToken = await firebase.auth().currentUser.getIdToken(true)
    const {
      data,
    } = await httpClient.get('/accounting/data', {
      params: { clientId },
      ...authorizationHeader(idToken),
    })
    resolve(data)
  } catch (err) {
    const e = handleErrors(err)
    reject(e)
  }
})

export const getAllAccountingData = () => new Promise(async (resolve, reject) => {
  try {
    const idToken = await firebase.auth().currentUser.getIdToken(true)
    const {
      data,
    } = await httpClient.get('/accounting/accounting-data', {
      ...authorizationHeader(idToken),
    })
    resolve(data)
  } catch (err) {
    const e = handleErrors(err)
    reject(e)
  }
})