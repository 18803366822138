import * as createShareholder from './createShareholder'
import * as deleteShareholder from './deleteShareholder'
import * as getAllShareholders from './getAllShareholders'
import * as getShareholder from './getShareholder'
import * as updateShareholder from './updateShareholder'
import * as verifyShareholder from './verifyShareholder'
export default {
    ...createShareholder,
    ...deleteShareholder,
    ...getShareholder,
    ...getAllShareholders,
    ...updateShareholder,
    ...verifyShareholder
}
