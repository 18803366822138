import {
  authorizationHeader,
  httpClient,
  handleErrors,
} from '@utils/httpClient'

import firebase from '@src/firebase/app'

export const deleteShareholder = (clientId) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      await httpClient.delete('/shareholder', {
        body: {
          clientId,
        },
        ...authorizationHeader(idToken),
      })
      resolve()
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })

export const deleteClientShareholder = (clientShareholdersId) => new Promise(async(resolve, reject) => {
  try {
    const idToken = await firebase.auth().currentUser.getIdToken(true)
    await httpClient.delete('/client/shareholders', {
      body: {
        clientShareholdersId,
      },
      ...authorizationHeader(idToken),
    })
    resolve()
  } catch (err) {
    const e = handleErrors(err)
    reject(e)
  }
})