import {
  httpClient,
  authorizationHeader,
  handleErrors,
} from '@src/utils/httpClient'
import firebase from '@src/firebase/app'

export const getShareholderByClientId = (clientId) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const { data: shareholder } = await httpClient.get('/shareholder', {
        params: { clientId },
        ...authorizationHeader(idToken),
      })
      resolve(shareholder)
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })

export const getShareholdersByClientShareholders = (clientId) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data: { shareholders },
      } = await httpClient.get('/client/shareholders', {
        params: {
          clientId,
        },
        ...authorizationHeader(idToken),
      })
      resolve(shareholders)
    } catch (err) {
      const error = handleErrors(err)
      reject(error)
    }
  })

export const getShareholderByNameAndType = ({
  shareholderName,
  shareholderType,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data: { shareholders },
      } = await httpClient.get('/shareholder', {
        params: {
          shareholderName,
          shareholderType,
        },
        ...authorizationHeader(idToken),
      })
      resolve(shareholders)
    } catch (err) {
      const error = handleErrors(err)
      reject(error)
    }
  })

export const getShareholderByName = ({ name }) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data: { shareholders },
      } = await httpClient.get('/shareholder', {
        params: {
          name,
        },
        ...authorizationHeader(idToken),
      })
      resolve(shareholders)
    } catch (err) {
      const error = handleErrors(err)
      reject(error)
    }
  })
