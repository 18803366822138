import {
  httpClient,
  authorizationHeader,
  handleErrors,
} from '@src/utils/httpClient'
import firebase from '@src/firebase/app'

export const verifyShareholder = (payload) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      await httpClient.post(
        '/shareholder/corporate',
        {
          body: { ...payload },
          ...authorizationHeader(idToken),
        }
      )
      resolve()
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })
