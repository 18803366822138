import {
  authorizationHeader,
  httpClient,
  authMultipartHeaderWithParams,
  handleErrors,
} from '@utils/httpClient'

import firebase from '@src/firebase/app'

export const acceptDocument = (documentId) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      await httpClient.post('/document/accept', {
        body: {
          documentId,
        },
        ...authorizationHeader(idToken),
      })
      resolve()
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })

export const createDocument = (formData) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      await httpClient.post(`/document`, {
        body: formData,
        ...authMultipartHeaderWithParams(idToken, {}),
      })
      resolve()
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })
