import {
  authMultipartHeaderWithParams,
  httpClient,
  handleErrors,
} from '@utils/httpClient'

import firebase from '@src/firebase/app'

export const createAccounting = ({formData, clientType }) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      await httpClient.post(`/accounting`, {
        body: formData,
        ...authMultipartHeaderWithParams(idToken, {
          'client-type': clientType,
        }),
      })
      resolve()
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })
