import * as createAccounting from './createAccounting'
import * as getAccounting from './getAccounting'
import * as deleteAccounting from './deleteAccounting'
import * as updateAccountData from './updateAccountData'
export default {
  ...createAccounting,
  ...deleteAccounting,
  ...getAccounting,
  ...updateAccountData
}
