import firebase from '@src/firebase/app'
import {
  httpClient,
  authorizationHeader,
  authMultipartHeaderWithParams,
  handleErrors,
} from '@utils/httpClient'
import { documentsToFormData } from '@src/utils/documentsToFormData'
import { ClientTypesFull } from '@src/constants/index.js'

export const updateShareholderDetails = (payload) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const shareholder = await httpClient.put(`/shareholder`, {
        body: { ...payload },
        ...authorizationHeader(idToken),
      })
      resolve(shareholder)
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })

export const updateShareholderDocuments = ({
  clientId,
  name,
  documents,
  shareholderType,
  forceErrorMsg = false,
}) =>
  new Promise(async (resolve, reject) => {
    const formData = documentsToFormData(documents, { clientId, name })

    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data: { documents },
      } = await httpClient.put(`/shareholder/documents`, {
        body: formData,
        ...authMultipartHeaderWithParams(idToken, {
          'client-type': ClientTypesFull.SHAREHOLDER,
          'shareholder-type': shareholderType,
        }),
      })
      resolve(documents)
    } catch (error) {
      if (forceErrorMsg) {
        const errMsg = {
          message: `
            Shareholder has been created successfully, but there has been a problem loading documents.
            You should upload them in the documents section
        `,
        }
        reject(errMsg)
        return
      }
      const e = handleErrors(error)
      reject(e)
    }
  })
