import {
  httpClient,
  authorizationHeader,
  handleErrors,
} from '@src/utils/httpClient'
import firebase from '@src/firebase/app'

export const generateXlsx = () =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const { data: xlsx } = await httpClient.get('/xlsx', {
        params: {},
        ...authorizationHeader(idToken),
        responseType: 'blob',
      })
      resolve(xlsx)
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })
