import {
  authorizationHeader,
  httpClient,
  handleErrors,
} from '@utils/httpClient'

import firebase from '@src/firebase/app'
export const deleteAccounting = (accountingId) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      await httpClient.delete('/accounting', {
        body: {
          accountingId,
        },
        ...authorizationHeader(idToken),
      })
      resolve()
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })
