import {
  authorizationHeader,
  httpClient,
  handleErrors,
} from '@utils/httpClient'

import firebase from '@src/firebase/app'

export const createAccountEmployee = ({payload}) =>
  new Promise(async (resolve, reject) => {
         console.log("payload",payload);
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data,
      } = await httpClient.post(`/accounting/employee-data`, {
      body: payload,
      ...authorizationHeader(idToken),
    }) 
      resolve(data)
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })
