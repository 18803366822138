import {
  httpClient,
  authorizationHeader,
  authMultipartHeaderWithParams,
  handleErrors,
} from '@src/utils/httpClient'
import firebase from '@src/firebase/app'
import { documentsToFormData } from '@src/utils/documentsToFormData'

export const createClient = (payload) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const { data: client } = await httpClient.post('/client', {
        body: { ...payload },
        ...authorizationHeader(idToken),
      })
      resolve(client)
    } catch (err) {
      const error = handleErrors(err)
      reject(error)
    }
  })

export const createClientDocuments = ({ client, documents, organigramData }) =>
  new Promise(async (resolve, reject) => {
    const { clientId, clientName, companyName, clientType } = client
    const name = clientName || companyName
    const formData = documentsToFormData(documents, {
      clientId,
      name,
      clientType,
    })
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
          formData.append('organigram', organigramData)
      const {
        data: { documents },
      } = await httpClient.post(`/client/documents`, {
        body: formData,
        ...authMultipartHeaderWithParams(idToken, {
          'client-type': clientType,
        }),
      })
      resolve(documents)
    } catch (_) {
      const err = `
        Client has been created successfully, but there has been a problem loading documents.
        You should upload them in the documents section
        `
      reject(err)
    }
  })

export const createClientShareholders = ({ client, shareholdersId }) =>
  new Promise(async (resolve, reject) => {
    const { clientId } = client
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      await httpClient.post('/client/shareholders', {
        body: {
          shareholdersId,
          clientId,
        },
        ...authorizationHeader(idToken),
      })
      resolve()
    } catch (err) {
      const error = handleErrors(err)
      reject(error)
    }
  })
