import {
    authorizationHeader,
    httpClient,
    handleErrors,
  } from '@utils/httpClient'
  
  import firebase from '@src/firebase/app'
  
  export const updateAccountData = ({payload}) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data
      } = await httpClient.put(`/accountingData/data`, {
        body: payload,
        ...authorizationHeader(idToken),
      }) 
      resolve(data)
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })

  