import * as createClient from './createClient'
import * as deleteClient from './deleteClient'
import * as getClients from './getClients'
import * as generateXlsx from './generateXlsx'
import * as updateClient from './updateClient'

export default {
    ...createClient,
    ...deleteClient,
    ...getClients,
    ...generateXlsx,
    ...updateClient
}
