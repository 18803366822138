import {
  authorizationHeader,
  httpClient,
  handleErrors,
} from '@utils/httpClient'

import firebase from '@src/firebase/app'

export const updateRisk = (payload) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      await httpClient.put(`/risk`, {
        body: { ...payload },
        ...authorizationHeader(idToken),
      })
      resolve()
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })
