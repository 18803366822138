import {
  authorizationHeader,
  httpClient,
  handleErrors,
} from '@utils/httpClient'

import firebase from '@src/firebase/app'

export const getAllAccountings = () => new Promise(async(resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data: { accountings },
      } = await httpClient.get('/accounting/all', {
        ...authorizationHeader(idToken),
      })
      resolve(accountings)
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
})

export const getAccountingByAccountingId = (accountingId,clientType) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const { data: accounting } = await httpClient.get('/accounting', {
        params: { accountingId, clientType },
        ...authorizationHeader(idToken),
      })
      resolve(accounting)
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
})