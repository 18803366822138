import {
  authorizationHeader,
  httpClient,
  handleErrors,
} from '@utils/httpClient'

import firebase from '@src/firebase/app'

export const getAllRisks = () =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data: { clients },
      } = await httpClient.get('/risk/all', {
        ...authorizationHeader(idToken),
      })
      resolve(clients)
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })
