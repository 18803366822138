import {
  authorizationHeader,
  httpClient,
  handleErrors,
} from '@utils/httpClient'

import firebase from '@src/firebase/app'

export const getAllReminders = ({ limit } = {}) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data: { reminders },
      } = await httpClient.get(`/reminders/all`, {
        params: { ...(limit && { limit }) },
        ...authorizationHeader(idToken),
      })
      resolve(reminders)
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })
