import {
  httpClient,
  authorizationHeader,
  authMultipartHeaderWithParams,
  handleErrors,
} from '@src/utils/httpClient'
import firebase from '@src/firebase/app'
import { documentsToFormData } from '@src/utils/documentsToFormData'
import { ClientTypesFull } from '@src/constants/index.js'
export const createShareholder = (payload) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const { data: shareholder } = await httpClient.post('/shareholder', {
        body: { ...payload },
        ...authorizationHeader(idToken),
      })
      resolve(shareholder)
      //       return this.saveShareholderDocuments(shareholder)
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })

export const createShareholderDocuments = ({
  clientId,
  name,
  documents,
  shareholderType,
  forceErrorMsg = false,
}) =>
  new Promise(async (resolve, reject) => {
    const formData = documentsToFormData(documents, { clientId, name })

    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data: { documents },
      } = await httpClient.post(`/shareholder/documents`, {
        body: formData,
        ...authMultipartHeaderWithParams(idToken, {
          'client-type': ClientTypesFull.SHAREHOLDER,
          'shareholder-type': shareholderType,
        }),
      })
      resolve(documents)
    } catch (error) {
      if (forceErrorMsg) {
        const errMsg = `
            Shareholder has been created successfully, but there has been a problem loading documents.
            You should upload them in the documents section
        `
        reject(errMsg)
        return
      }
      const e = handleErrors(error)
      reject(e)
    }
  })
