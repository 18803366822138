import {
    authorizationHeader,
    httpClient,
    handleErrors,
  } from '@utils/httpClient'
  
  import firebase from '@src/firebase/app'
  export const deleteDocument = (documentId) =>
    new Promise(async (resolve, reject) => {
      try {
        const idToken = await firebase.auth().currentUser.getIdToken(true)
        await httpClient.delete('/document', {
          body: {
            documentId,
          },
          ...authorizationHeader(idToken),
        })
        resolve()
      } catch (err) {
        const error = handleErrors(err)
        reject(error)
      }
    })
  