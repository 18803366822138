import {
  httpClient,
  authorizationHeader,
  handleErrors,
} from '@src/utils/httpClient'
import firebase from '@src/firebase/app'

export const getClientByClientId = (clientId) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const { data: client } = await httpClient.get('/client', {
        params: { clientId },
        ...authorizationHeader(idToken),
      })
      resolve(client)
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })

export const getClientsByName = ({ name, shareholders }) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data: { clients },
      } = await httpClient.get('/client', {
        params: {
          name,
          ...(shareholders && { shareholders }),
        },
        ...authorizationHeader(idToken),
      })
      resolve(clients)
    } catch (err) {
      const error = handleErrors(err)
      reject(error)
    }
  })

export const getAllClients = () =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data: { clients },
      } = await httpClient.get('/client/all', {
        body: {},
        ...authorizationHeader(idToken),
      })
      resolve(clients)
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })

export const getAllIndividuals = ({ limit }) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data: { individuals },
      } = await httpClient.get('/individual/all', {
        params: { ...(limit && { limit }) },
        ...authorizationHeader(idToken),
      })
      resolve(individuals)
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })

export const getAllCorporates = ({ limit }) =>
  new Promise(async (resolve, reject) => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const {
        data: { corporates },
      } = await httpClient.get('/corporate/all', {
        params: { ...(limit && { limit }) },
        ...authorizationHeader(idToken),
      })
      resolve(corporates)
    } catch (err) {
      const e = handleErrors(err)
      reject(e)
    }
  })
