export const documentsToFormData = (documents, params) => {
  const { comment, files, documentNumbers, expiryDates } = documents.reduce(
    (acc, document) => {
      const { comment, type, number, expiryDate, file } = document

      const { documentNumbers, files, expiryDates } = acc

      if (!file) {
        return {
          documentNumbers,
          files,
          expiryDates,
        }
      }
      return {
        comment,
        documentNumbers: {
          ...documentNumbers,
          [type]: number,
        },
        files: {
          ...files,
          [type]: file,
        },
        expiryDates: {
          ...expiryDates,
          [type]: expiryDate,
        },
      }
    },
    {
      comment: '',
      files: {},
      documentNumbers: {},
      expiryDates: {},
    }
  )

 let formData = new FormData()

    formData = Object.keys(params).reduce((acc, param) => {
      if (!params[param]) return acc
      acc.append(param, params[param])
      return acc
    }, formData)

  formData = Object.keys(files).reduce((acc, file) => {
    acc.append(file, files[file])
    return acc
  }, formData)
  formData.append('comment', comment)
  formData.append('documentNumbers', JSON.stringify(documentNumbers))
  formData.append('expiryDates', JSON.stringify(expiryDates))
  return formData
}
