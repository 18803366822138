import * as getAccountingData from './getAccountingData'
import * as deleteAccountingData from './deleteAccountingData'
import * as getVatReturnsData from './getVatReturnsData'
import * as deleteVatReturnData from './deleteVatReturnData'
import * as getVatRecapsData from './getVatRecapsData'
import * as deleteVatRecapsData from './deleteVatRecapsData'
import * as getAccountingTax from './getAccountingTax'
import * as deleteAccountingTax from './deleteAccountingTax'
import * as createAccountingData from './createAccountingData'
import * as updateAccountingData from './updateAccountingData'
import * as createVatReturnData from './createVatReturnData'
import * as updateVatReturnData from './updateVatReturnData'
import * as createVatRecapData from './createVatRecapData'
import * as updateVatRecapData from './updateVatRecapData'
import * as createTaxData from './createTaxData'
import * as updateTaxData from './updateTaxData'
import * as createAccountEmployee from './createAccountEmployee'
import * as updateAccountEmployee from './updateAccountEmployee'
import * as getAccountEmployeeData from './getAccountEmployeeData'
import * as deleteEmployeeData from './deleteEmployeeData'
import * as getAllClientsNameId from './getAllClientsNameId'

export default {
    ...getAccountingData,
    ...deleteAccountingData,
    ...getVatReturnsData,
    ...deleteVatReturnData,
    ...getVatRecapsData,
    ...deleteVatRecapsData,
    ...getAccountingTax,
    ...deleteAccountingTax,
    ...createAccountingData,
    ...updateAccountingData,
    ...createVatReturnData,
    ...updateVatReturnData,
    ...createVatRecapData,
    ...updateVatRecapData,
    ...createTaxData,
    ...updateTaxData,
    ...createAccountEmployee,
    ...updateAccountEmployee,
    ...getAccountEmployeeData,
    ...deleteEmployeeData,
    ...getAllClientsNameId
}